import { RichText } from 'prismic-reactjs'
import React from 'react'
import Container from '../../elements/container'
import { Wrapper, Section, Content } from './style'

const TextContent = ({ content }: any) => {
  return (
    <>
      <Section>
        <Container>
          <Wrapper>
            <Content>{RichText.render(content)}</Content>
          </Wrapper>
        </Container>
      </Section>
    </>
  )
}

export default TextContent
