/** @jsx jsx */

import styled from '@emotion/styled'

export const BackgroundGradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(318.28deg, #dbf8ff 17.86%, #eef6ff 54.42%, #faefff 91.82%);
  mix-blend-mode: normal;
  opacity: 0.5;
`
