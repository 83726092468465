import React from 'react'
import TextContent from '../../components/content_sections/text_content'
import CenterHero from '../../components/hero/center_hero'
import Layout from '../../components/layout'
import { BackgroundGradient } from '../../components/elements/background_gradient'

const PrivacyPolicyPage = ({ pageContext: { data, localePath } }: any) => {
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <CenterHero
        background_shape={<BackgroundGradient />}
        title={data.header_title.raw}
        textAlignment="center"
        call_to_action_color={data.slug}
      />
      <TextContent content={data.body[0].primary.content.raw} />
    </Layout>
  )
}

export default PrivacyPolicyPage
